var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('loading'):(!_vm.studentsPermission.show)?_c('content-not-view'):_c('div',{staticClass:"course-students"},[_c('b-row',[_c('b-card',[_c('div',{staticClass:"card-header"},[_c('b-card-sub-title',[_vm._v(" "+_vm._s(_vm.courseDetails.name)+" "),_c('span',{staticClass:"gray-text"},[_vm._v("."+_vm._s(_vm.$t("g.Students")))])]),(_vm.courseDetails.is_summer)?_c('b-card-sub-title',{staticClass:"gray-text"},[_vm._v(" "+_vm._s(_vm.$t("g.Grade"))+" "),_c('span',{staticClass:"spcial-text"},[_vm._v(" ."+_vm._s(_vm.$t("g.summer_course"))+" ")])]):_c('b-card-sub-title',{staticClass:"gray-text"},[_vm._v(" "+_vm._s(_vm.$t("g.Grade"))+" "),_vm._l((_vm.courseDetails.edu_years),function(eduYear,index){return _c('span',{key:index,staticClass:"spcial-text"},[_vm._v(" ."+_vm._s(eduYear.name)+" ")])})],2)],1),(_vm.studentsPermission.store)?_c('b-link',{attrs:{"to":("/class-rooms/show-course/" + _vm.course_id + "/add-students")}},[_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("g.courses_students/add_students"))+" ")])],1):_vm._e()],1)],1),(_vm.students.length > 0)?_c('b-row',_vm._l((_vm.students),function(student){return _c('b-col',{key:student.user.id,staticClass:"d-flex align-items-start",attrs:{"cols":"auto"}},[(student.user.user_type === 'student')?_c('b-link',{attrs:{"to":("/show-student/" + (student.id))}},[_c('div',{staticClass:"student-card d-flex flex-column justify-content-center align-items-center mb-4"},[_c('b-avatar',{attrs:{"size":"6rem","src":_vm.$helpers.filterImages(
                _vm.$helpers.uiAvatar(
                  student.user.first_name + ' ' + student.user.last_name
                ),
                'path',
                student.user.media
              ),"img-alt":student.user.full_name}}),_c('b-card-sub-title',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(student.user.full_name)+" ")])],1)]):_vm._e(),(
          _vm.studentsPermission.destroy &&
          student.enrollments &&
          student.enrollments.length
        )?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.deleteIcon,"alt":"information"},on:{"click":function($event){return _vm.deleteStudentFromCourse(student.enrollments[0].id)}}}):_vm._e()],1)}),1):_c('no-data-component')],1)}
var staticRenderFns = []

export { render, staticRenderFns }