<template>
  <loading v-if="isLoading" />
  <content-not-view v-else-if="!studentsPermission.show" />
  <div class="course-students" v-else>
    <b-row>
      <b-card>
        <div class="card-header">
          <b-card-sub-title>
            {{ courseDetails.name }}
            <span class="gray-text">.{{ $t("g.Students") }}</span>
          </b-card-sub-title>
          <b-card-sub-title class="gray-text" v-if="courseDetails.is_summer">
            {{ $t("g.Grade") }}
            <span class="spcial-text"> .{{ $t("g.summer_course") }} </span>
          </b-card-sub-title>
          <b-card-sub-title class="gray-text" v-else>
            {{ $t("g.Grade") }}
            <span
              class="spcial-text"
              v-for="(eduYear, index) in courseDetails.edu_years"
              :key="index"
            >
              .{{ eduYear.name }}
            </span>
          </b-card-sub-title>
        </div>
        <b-link
          :to="`/class-rooms/show-course/${course_id}/add-students`"
          v-if="studentsPermission.store"
        >
          <b-button variant="primary">
            {{ $t("g.courses_students/add_students") }}
          </b-button>
        </b-link>
      </b-card>
    </b-row>
    <b-row v-if="students.length > 0">
      <b-col
        cols="auto"
        class="d-flex align-items-start"
        v-for="student in students"
        :key="student.user.id"
      >
        <b-link
          v-if="student.user.user_type === 'student'"
          :to="`/show-student/${student.id}`"
        >
          <div
            class="student-card d-flex flex-column justify-content-center align-items-center mb-4"
          >
            <b-avatar
              size="6rem"
              :src="
                $helpers.filterImages(
                  $helpers.uiAvatar(
                    student.user.first_name + ' ' + student.user.last_name
                  ),
                  'path',
                  student.user.media
                )
              "
              :img-alt="student.user.full_name"
            />
            <b-card-sub-title class="mt-2">
              {{ student.user.full_name }}
            </b-card-sub-title>
          </div>
        </b-link>
        <img
          v-if="
            studentsPermission.destroy &&
            student.enrollments &&
            student.enrollments.length
          "
          style="cursor: pointer"
          @click="deleteStudentFromCourse(student.enrollments[0].id)"
          :src="deleteIcon"
          alt="information"
        />
      </b-col>
    </b-row>
    <no-data-component v-else />
  </div>
</template>

<script>
import loading from "@/components/loading/loading.vue";
import {
  BCard,
  BRow,
  BCol,
  BCardSubTitle,
  BAvatar,
  BLink,
  BButton,
} from "bootstrap-vue";
import contentNotView from "@/components/contentNotView/contentNotView.vue";
import NoDataComponent from "@/components/shared/NoDataComponent/index.vue";
export default {
  components: {
    NoDataComponent,
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
    loading,
    BButton,
    BCardSubTitle,
    contentNotView,
  },
  data() {
    return {
      isLoading: true,
      course_id: null,
      students: null,
      courseDetails: null,
      deleteIcon: require("@/assets/images/icons/trash-gray.svg"),
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.course_id = +this.$route.params.id;
      this.getStudents(this.$route.params.id);
      this.getCourse(this.$route.params.id);
    } else {
      this.$router.push("/");
    }
  },
  computed: {
    studentsPermission() {
      this.$store.dispatch("GET_PERMISSION", "students");
      return this.$store.state.userData.sub_Permission;
    },
  },
  methods: {
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    async getStudents(id) {
      try {
        const res = await this.$http.get(
          `/admin/courses/${id}/students?enrolled=true`
        );
        console.log(res);
        this.students = res.data.data;
        this.courseDetails = res.data.course;
      } catch (error) {
        this.$helpers.handleError(error);
        this.getCourse(id);
      } finally {
        this.isLoading = false;
      }
    },
    getCourse(id) {
      this.$http
        .get(`/admin/courses/${id}`)
        .then((res) => {
          this.courseDetails = res.data.data;
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    async deleteStudentFromCourse(id) {
      try {
        const res = await this.$http.delete(`admin/enrollments/${id}`);
        this.isLoading = true;
        if (res.status === 200 || res.status === 201) {
          this.$helpers.makeToast(
            "success",
            res.data.message,
            this.$t("g.edu_year/edu_year_added_successfully")
          );
          this.getStudents(this.course_id);
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_courseStudents.scss";
</style>
